<template>
    <standard-page :definition="$DEFINITIONS.didDocument" sub_title="Add DID Document Properties" v-bind:title="short_name">
        <template v-slot:breadcrumb>
      <span>
        <router-link :to="{ name: 'identities-home'}">Identifier</router-link>
      </span>
            <span>
        <router-link :to="{ name: 'identities-catalogs'}">Catalogs</router-link>
      </span>
            <span>
        <router-link :to="{ name: 'identities-catalogs-dids'}" v-if="catalogName">CATALOG: {{catalogName | truncate(20, '.....')}}</router-link>
      </span>
            <span v-if="short_name">DID: {{short_name | truncate(20, '.....')}}</span>
        </template>
        <template v-slot:sub-title>
            <p class="mg-b-10">
                <a href="javascript:void(0);" v-clipboard:copy="did" v-clipboard:success="copied">
                    {{did | did}}
                </a>
            </p>
        </template>

        <template v-slot:content>
            <div class="tab-pane-body">
                <div class="pd-30 pd-sm-40 bg-gray-200" v-if="properties.length>0">
                    <div class="row row-xs align-items-center mg-b-20" v-for="(property, index) in properties">
                        <div class="col-md-1 d-none d-xl-block">
                            <label class="form-label mg-b-0">{{index+1}}.</label>
                        </div>
                        <div class="col-md-3 mg-t-5 mg-md-t-0">
                            <input class="form-control rounded" id="did_property" name="did_property" placeholder="Enter Name" required type="text" v-model="property.name">
                        </div>
                        <div class="col-md-2 mg-t-5 mg-md-t-0">
                            <select aria-hidden="true" class="form-control select2-no-search select2-hidden-accessible rounded" data-select2-id="13" tabindex="-1" v-model="property.type">
                                <option v-bind:value=null>--- Select a type ---</option>
                                <option v-bind:key="index" v-for="(type, index) in data_types">{{type}}</option>
                            </select>
                        </div>
                        <div class="col-md-2 mg-t-5 mg-md-t-0" v-if="property.type === 'Text'">
                            <input class="form-control rounded" id="property_string_value" name="property_string_value" placeholder="Enter value" required type="text" v-model="property.value">
                        </div>
                        <div class="col-md-2 mg-t-5 mg-md-t-0" v-if="property.type === 'Number'">
                            <input class="form-control rounded" id="property_u8_value" name="property_u8_value" placeholder="Enter value" required type="number" v-model="property.value">
                        </div>
                        <div class="col-md-2 mg-t-5 mg-md-t-0" v-if="property.type === 'Yes/No'">
                            <label class="rdiobox">
                                <input :name="index+'_yesno'" type="radio" v-bind:value=true v-model="property.value">
                                <span>Yes</span>
                            </label>
                            <label class="rdiobox">
                                <input :name="index+'_yesno'" type="radio" v-bind:value=false v-model="property.value">
                                <span>No</span>
                            </label>
                        </div>
                        <div class="custom-file col-md-2 mg-t-5 mg-md-t-0" v-if="property.type === 'File'">
                            <input :id="index" :name="property.name" @change="fileChange" class="custom-file-input" type="file">
                            <label :for="index" class="custom-file-label">{{property.file_name}}</label>
                        </div>
                        <div class="col-md-2 mg-t-5 mg-md-t-0">
                            <div class="form-check form-check-inline">
                                <input :class="{ 'is-invalid' : submitted && errors.has('required'+index) }" :name="'required'+index" :value="true" class="form-check-input" type="radio"
                                       v-model="property.private" v-validate="'required'">
                                <label class="form-check-label" for="Yes">Private</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input :class="{ 'is-invalid' : submitted && errors.has('required'+index) }" :name="'required'+index" :value="false" class="form-check-input" type="radio"
                                       v-model="property.private">
                                <label class="form-check-label" for="No">Public</label>
                            </div>
                        </div>
                        <div class="col-md mg-t-10 mg-md-t-0" v-if="properties.length> 1">
                            <a @click="removeProperty(index)" class="btn btn-block btn-danger rounded" href="javascript:void(0)"><i class="fa fa-trash-alt"></i></a>
                        </div>
                        <div class="col-md mg-t-10 mg-md-t-0">
                            <a @click="addProperty" class="btn btn-block btn-success rounded" href="javascript:void(0)" type="button" v-if="index+1 === properties.length"><i
                                    class="fa fa-plus-square"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-sm mg-t-20">
                <div class="col-lg-2 mg-t-5 offset-lg-8">
                    <button @click="addProperties" class="btn btn-primary w-100 rounded font-weight-bold" type="button"> Submit
                    </button>
                </div>
                <div class="col-lg-2 mg-t-5">
                    <button @click="reset" class="btn btn-secondary w-100 rounded font-weight-bold" type="button"> Reset
                    </button>
                </div>
            </div>

        </template>
    </standard-page>

</template>

<script>
    import Multiselect from 'vue-multiselect';
    import EventBus from "@/event-bus";

    export default {
        name: "AddDidRecord",
        components: {Multiselect},
        props: ['catalogid', 'catalogNameProp', 'did', 'short_nameProp'],
        data() {
            return {
                catalogName: this.catalogNameProp,
                short_name: this.short_nameProp,
                submitted: false,
                name: null,
                properties: [],
                request_properties: [],
                data_types: ['Text', 'Number', 'Float', 'Yes/No', 'Date', 'File', 'Location'],
                selected_data_type: null,
                did_part: null,
                did_attachments: []
            };
        },
        mounted() {
            this.init();
        },
        methods: {
            async init() {
                if (!this.catalogName) {
                    this.getCatalog();
                }
                if (!this.short_name) {
                    this.getDid();
                }
                this.addProperty();
            },
            async getCatalog() {
                try {
                    let reply = await this.$identity.get(`/identity/catalogs/${this.catalogid}`);
                    this.catalogName = reply.data ? reply.data.name : null;
                } catch (e) {
                    this.$toastr.e("Error getting catalog name", "Error");
                }
            },
            async getDid() {
                try {
                    let reply = await this.$identity.get(`/identity/catalogs/${this.catalogid}/dids/${this.did}`);
                    this.short_name = reply.data ? reply.data.short_name : null;
                } catch (e) {
                    this.$toastr.e("Error getting DID short_name", "Error");
                }
            },
            async addProperty() {
                this.properties.push({
                    name: null,
                    private: true,
                    value: null,
                    type: 'Text',
                    is_null: true,
                    file_name: 'Upload'
                });
            },
            getDatatype(type, val) {
                switch (type) {
                    case "Text":
                        return 'String';
                    case "Number":
                        if (val >= 0 && val < 256) {
                            return "U8";
                        } else if (val > 255 && val < 65537) {
                            return "U16";
                        } else if (val > 65536 && val < 4000001) {
                            return "U32";
                        } else if (val) {
                            return "U128";
                        }
                    case "Yes/No":
                        return "Bool";

                }
            },
            removeProperty(index) {
                this.properties.splice(index, 1);
            },
            async fileChange(e) {
                this.did_attachments = this.did_attachments.filter(a => a.name !== e.target.name)
                let file = e.target.files[0];
                this.properties[e.target.id].file_name = file.name;
                this.did_attachments.push({
                    name: e.target.name,
                    file: file
                });
            },
            async addProperties() {
                await this.arrangeProperties();
                try {
                    this.submitted = true;
                    let valid = await this.$validator.validate();

                    if (valid) {
                        if (this.request_properties.length > 0) {
                            await this.$identity.patch(`/identity/dids/${this.did}`, {
                                add: this.request_properties
                            });
                        }
                        await this.attachDidDocs();
                        this.$toastr.s("DID records added successfully", "Success");
                        this.reset();
                        await this.$router.push({name: 'viewdid'});
                    }
                } catch (e) {
                    this.$toastr.e("DID records add failed", "Error");
                }
            },
            async arrangeProperties() {
                this.properties.forEach(property => {
                    if (property.type !== 'File') {
                        let type = this.getDatatype(property.type, property.value);
                        let req_obj = {
                            name: property.name,
                            type: type,
                            private: property.private,
                            value: property.value.toString()
                        };
                        this.request_properties.push(req_obj);
                    }
                });
            },
            async attachDidDocs() {
                for (let i = 0; i < this.did_attachments.length; i++) {
                    let attachment = this.did_attachments[i];
                    let f = new FormData();
                    f.append('file', attachment.file);
                    await this.$identity.post(`identity/dids/${this.did}/documents?property_name=${attachment.name}`, f, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': 'BWS ' + localStorage.getItem("token")
                        }
                    })
                }
            },
            reset() {
                this.properties = [];
            },
            copied() {
                this.$toastr.i("Copied to clipboard", "Copied");
            }
        }
    }
</script>

<style scoped></style>